
import { useProfile } from '@/composables/profile/useProfile'
import { extractHashtags } from '@/composables/utils/ExtractHashtags'
import { computed, defineComponent, unref } from 'vue'

export default defineComponent({
  props: {
    thumbnail: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { profile } = useProfile()
    
    const profilePick = computed(() => {
      return unref(profile).profilePick ? unref(profile).profilePick : ''
    })

    const profileName = computed(() => {
      return unref(profile).name
    })

    const caption = computed(() => {
      let content = unref(props.content)
      extractHashtags(content).map(hashtag => {
        content = content.replace(hashtag, `<span class="text-blue-400 cursor-pointer">${hashtag}</span>`)
      })
      return content
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      caption,
      addDefaultSrc,
      profilePick,
      profileName
    }
  }
})
